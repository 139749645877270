@font-face {
  font-family: DefaultFont;
  src: url('./fonts/SourceSansPro-Regular.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url('./fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: MontserratBlack;
  src: url('./fonts/Montserrat-Black.ttf');
}

@font-face {
  font-family: MontserratBold;
  src: url('./fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: DefaultFont;
  src: url('./fonts/SourceSansPro-SemiBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: DefaultFontBold;
  src: url('./fonts/SourceSansPro-Bold.ttf');
  font-weight: bold;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: 'DefaultFont', sans-serif;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}

/* #viewer.scrolled {
  overflow: hidden;
  width: 800px;
  margin: 0 auto;
  position: relative;
  box-shadow: 0 0 4px #ccc;
  padding: 20px;
  background: white;
}

#viewer.scrolled .epub-view > iframe {
  background: white;
} */

#viewer.scrolled {
  overflow: hidden;
  margin: 0;
  position: relative;
  background: white;
}

#viewer.scrolled .epub-view > iframe {
  background: white;
  overflow: hidden;
}

#viewer.scrolled .epub-view > iframe > p {
  color: red;
}

#viewer.scrolled .epub-container {
  overflow-x: hidden !important;
}

#viewer.scrolled .epub-container .epub-view > iframe > figure > img {
  max-height: none !important;
  height: none !important;
}

/* iframe {
  background-color: green !important;
} */

iframe > body > div.image > img {
  max-height: none !important;
}

.modalStyle {
  @media (max-width: 360px) {
    width: 100% !important;
    padding: 0% 15%;
    grid-gap: 20px;
    grid-template-columns: repeat(3, auto);
  }
  @media (max-width: 1920px) {
    width: 1300px;
    height: 100vh;
  }
}

/* #book-viewer {
  @media (max-width: 360px) {
    padding: 0% 0%;
    width: 60%;
  }
} */

.tool {
  background: red;


  /* @media (max-width: 360px) {
    padding: 0% 0%;
    position: absolute;
    width: 100px;
  } */
}
